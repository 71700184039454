<template>
  <div class="credits pb-10">
    <h1 class="text-drawdown font-display md:text-mdlg text-mdbs tracking-wide leading-tight">
        Our team
    </h1>
    <p class="pb-2">
        A dedicated team through Inside the Greenhouse at the University of Colorado adapted the Project Drawdown solutions to a 5th-grade reading level and translated the top solutions into Spanish. We acknowledge the contributions of the 2019 CU Creative Climate Communication students who helped begin this adaptation work. We also thank the students and teachers of the 5th grade classes of University Hill Elementary School (a dual-immersion Spanish-English school in Boulder, Colorado) who shared their feedback early on in this process with our CU students. We also thank the 4th/5th grade teachers at Stober Elementary School in Lakewood, Colorado who contributed to this work early on. 
    </p>

    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Co-Leads for the Project
    </h2>
    <div class="sm:flex sm:flex-row sm:flex-wrap items-center">
        <img class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/12 object-scale-down" src="/assets/img/portraits/beth.png" alt="Beth">
        <p class="pb-2 sm:w-2/3 md:w-3/4 lg:w-5/12 sm:p-2 md:p-5">
            <span class="font-bold">Beth Osnes</span> PhD, is an Associate Professor of Theatre at the University of Colorado Boulder, and is an associate of the Environmental Studies faculty. She is passionate about using performance as a tool for women and youth to author an equitable, survivable and thrive-able future for all life and the ecosystems upon which all life depends. She is co-founder and co-director of Inside the Greenhouse, an endowed initiative on the CU campus to inspire creative communication on climate (www.insidethegreenhouse.net).  She toured her original musical Shine to select cities that were part of the Rockefeller Foundation 100 Resilient Cities Initiative to engage youth voices in resilience planning. Her most recent book Performance for Resilience: Engaging Youth on Energy and Climate through Music, Movement, and Theatre (Palgrave 2017) shares an account of each location reached and the lessons learned. She is co-founder of SPEAK.WORLD, an approach towards vocal empowerment for young omen for increased self-advocacy and civic participation that has been implemented in Guatemala, Tanzania, Egypt, and the USA.
        </p>
        <img class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/12 object-scale-down" src="/assets/img/portraits/patrick.png" alt="Patrick">
        <p class="pb-2 sm:w-2/3 md:w-3/4 lg:w-5/12 sm:p-2 md:p-5">
            <span class="font-bold">Patrick Chandler</span> is a PhD candidate in Environmental Studies at the University of Colorado, Boulder who has fifteen years’ experience working in and developing environmental education, stewardship, and science programs. His current focus is the co-creation of guidelines to help bring together partners from multiple fields to work together on projects for a sustainable future, including working with artists and arts organizations to catalyze collective action on social and environmental issues.
        </p>
    </div>

    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Adaptation and Editing by Graduate Interns
    </h2>
    <div class="sm:flex sm:flex-row sm:flex-wrap items-center">
        <img class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/12 object-scale-down" src="/assets/img/portraits/caitlin.png" alt="Caitlin">
        <p class="pb-2 sm:w-2/3 md:w-3/4 lg:w-5/12 sm:p-2 md:p-5">
            <span class="font-bold"> Caitlin G McC Fine</span> is a PhD candidate in the Equity, Bilingualism and Biliteracy program at the School of Education. A former elementary bilingual science teacher, she is dedicated to working with practitioners and students at the intersection of bi/multilingual education, science education, formative assessment, and teacher education. She uses participatory design-based research to put theory to work by collaboratively imagining, building, testing, reflecting upon and refining tools and routines to support translanguaging pedagogies in science classrooms. Additionally, she is interested in the ways in which co-design work focused on equitable language pedagogies supports shifts in teachers’ language ideologies and the ways in which they interpret students’ ideas.
        </p>
        <img class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/12 object-scale-down" src="/assets/img/portraits/danny.png" alt="Daniel">
        <p class="pb-2 sm:w-2/3 md:w-3/4 lg:w-5/12 sm:p-2 md:p-5">
            <span class="font-bold">Daniel Garzón</span> is a fourth-year doctoral candidate in the Equity, Bilingualism, and Biliteracy program in the School of Education at the University of Colorado Boulder. He was previously a Spanish teacher for the elementary level grades in the Washington, D.C. area. His research interests include racial/linguistic identity development, sociolinguistics, and educational linguistics. He is interested in expanding multilingual education and the use of culturally sustaining pedagogies for diverse communities.
        </p>
        
    </div>
   
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Graphic Design & Website Development
    </h2>
    <div class="sm:flex sm:flex-row">
        <img class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/12 object-scale-down" src="/assets/img/portraits/sarah.png" alt="Sarah">
        <p class="pb-2 sm:w-2/3 md:w-3/4 lg:w-11/12 sm:p-2 md:p-5">
            <span class="font-bold">Sarah Manning</span> is a designer and multimedia artist born in California who is currently based in Colorado. She recently graduated from the University of Colorado Boulder with a B.S. in Creative Technology and Design, Cum Laude. Her work combines art, design, and technology in both the analog and digital worlds. She is passionate about subjects that combines design with sustainability, especially in areas of modern consumerism and electronic waste.
        </p>
    </div>

    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Spanish Translation
    </h2>
    <div class="sm:flex sm:flex-row">
        <img class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/12 object-scale-down" src="/assets/img/portraits/saul.png" alt="Saul">
        <p class="pb-2 sm:w-2/3 md:w-3/4 lg:w-11/12 sm:p-2 md:p-5">
            <span class="italic py-2">along with Daniel Garzón, described above</span>
            <br>
            <span class="font-bold">Saúl Blanco</span> is a freelance architect from Cali, Colombia. He received his bachelors from the Universidad del Valle, and has worked with architecture firms internationally. His interests include sustainability, eco-friendly development, locally sourced materials, and climate/environmental education.
        </p>
    </div>

    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Adaptation and Editing by Undergraduate Interns
    </h2>
    <div class="sm:flex sm:flex-row sm:flex-wrap items-center">
        <img class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/12 object-scale-down" src="/assets/img/portraits/izzy.png" alt="Izzy">
        <p class="pb-2 sm:w-2/3 md:w-3/4 lg:w-5/12 sm:p-2 md:p-5">
            <span class="font-bold">Izzy Sofio</span> graduated from the University of Colorado at Boulder in May 2020 with a B.A. in Environmental Studies, Magna Cum Laude, a minor in Creative Writing, and with a certificate from the Eco-social Justice Leadership Program. She was so happy to be a part of the awesome team that worked so hard in creating the Project Drawdown Adaptations. She looks forward to students and educators using these solutions and hopes it inspires change in all who get to learn and work from it!
        </p>
        <img class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/12 object-scale-down" src="/assets/img/portraits/erzebet.png" alt="Erzebet">
        <p class="pb-2 sm:w-2/3 md:w-3/4 lg:w-5/12 sm:p-2 md:p-5">
            <span class="font-bold">Erzebet Kalwaitis</span> is a recent graduate from the University of Colorado Boulder majoring in Environmental Studies and Geography. 
        </p>
    </div>
    <div class="sm:flex sm:flex-row">
        
    </div>

    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Youth Peer-Review
    </h2>
    <div class="flex flex-row flex-wrap items-end">
        <p class="pb-2 w-full lg:w-5/12 self-center">
            5th Grade Peer reviewers of these adapted climate solutions include <span class="font-bold">Jaden Chang</span>, <span class="font-bold">Luna Fierer</span>, <span class="font-bold">Niko Striphas</span>, <span class="font-bold">Simone Tewksbury</span>, <span class="font-bold">Darya Yazdı</span>, and <span class="font-bold">Osel Yeh</span>, who are all living, learning, and acting up on climate in Boulder, Colorado. Many thanks to each of them and to <span class="font-bold">Phaedra Pezzullo</span>--Niko’s ma, CU professor, and Inside the Greenhouse co-director—who facilitated this weekly group.
        </p>
        <div class="px-1 w-1/4 md:w-1/8 lg:w-1/12 object-scale-down text-center">
            <img src="/assets/img/portraits/jaden.png" alt="Jaden">
            <span class="font-bold">Jaden</span>
        </div>
        <div class="px-1 w-1/4 md:w-1/8 lg:w-1/12 object-scale-down text-center">
            <img src="/assets/img/portraits/luna.png" alt="Luna">
            <span class="font-bold">Luna</span>
        </div>
        <div class="px-1 w-1/4 md:w-1/8 lg:w-1/12 object-scale-down text-center">
            <img src="/assets/img/portraits/simone.png" alt="Simone">
            <span class="font-bold">Simone</span>
        </div>
        <div class="px-1 w-1/4 md:w-1/8 lg:w-1/12 object-scale-down text-center">
            <img src="/assets/img/portraits/darya.png" alt="Darya">
            <span class="font-bold">Darya</span>
        </div>
        <div class="px-1 w-1/4 md:w-1/8 lg:w-1/12 object-scale-down text-center">
            <img src="/assets/img/portraits/osel.png" alt="Osel">
            <span class="font-bold">Osel</span>
        </div>
        <div class="px-1 w-1/4 md:w-1/8 lg:w-1/12 object-scale-down text-center">
            <img src="/assets/img/portraits/niko.png" alt="Niko">
            <span class="font-bold">Niko</span>
        </div>
        <div class="px-1 w-1/4 md:w-1/8 lg:w-1/12 object-scale-down text-center">
            <img src="/assets/img/portraits/phaedra.png" alt="Phaedra">
            <span class="font-bold">Phaedra</span>
        </div>
    </div>

    <h1 class="text-drawdown font-display md:text-mdlg text-mdbs tracking-wide leading-tight pt-5">
        Image credits
    </h1>
    <p class="pb-2 italic">
        <ul class="list-disc pb-2 not-italic">
            <li>Solution #1: Header - <a href="https://www.boulderfoodrescue.org/" target="_blank" class="underline">Courtesy of Boulder Food Rescue</a>; Photo 1 - Sarah Manning; Photo 2 - Isabel Cousins;</li>
            <li>Solution #2: Header -  MAIA Impact School; Photo 1 - Melisande Osnes; Photo 2 - Beth Osnes;</li>
            <li>Solution #3: Header - <a href="https://www.pexels.com/photo/variety-of-vegetables-1458694/" target="_blank" class="underline"> Ella Olsson from Pexels</a>; Photo 1 - Beth Osnes; Photo 2 - <a href="https://commons.wikimedia.org/wiki/File:Impossible_Burger_free_sample.jpg" target="_blank" class="underline">Dllu / Wikimedia Commons</a>;</li>
            <li>Solution #4: Header - <a href="https://unsplash.com/photos/JUAVCUMY008" target="_blank" class="underline"> Ashkan Forouzani on Unsplash</a>; Photo 1 - <a target="_blank" href="https://earthobservatory.nasa.gov/world-of-change/Ozone" class="underline">NASA Earth Observatory</a>; Photo 2 - Sarah Manning;</li>
            <li>Solution #5: Header - <a href="https://unsplash.com/photos/9G1kXjqOq4U" target="_blank" class="underline"> CHUTTERSNAP on Unsplash</a>; Photo 1 - Max Boykoff; Photo 2 - William J. Baker, Royal Botanic Gardens, Kew;</li>
            <li>Solution #6: Header - <a href="https://unsplash.com/photos/PRumW--tkc4" target="_blank" class="underline"> JOHANNA MONTOYA on Unsplash</a>; Photo 1 - Isabel Cousins; Photo 2 - REVE (Wind Energy and Electric Vehicle Magazine);</li>
            <li>Solution #7: Header - <a href="https://unsplash.com/photos/Rr4rmrcqRYE" target="_blank" class="underline"> Dewi Karuniasih on Unsplash</a>; Photo 1 - <a href="https://www.flickr.com/photos/paulkagame/" target="_blank" class="underline">Paul Kagame on Flickr</a>; Photo 2 - Isabel Cousins;</li>
            <li>Solution #8: Header - <a href="https://unsplash.com/photos/V4ZYJZJ3W4M" target="_blank" class="underline"> Zbynek Burival on Unsplash</a>; Photo 1 - Beth Osnes; Photo 2 - U.S. Department of the Interior on Wikimedia Commons;</li>
            <li>Solution #9: Header - <a href="https://commons.wikimedia.org/wiki/File:Clean_Cooking_in_Refugee_Settlement.jpg" target="_blank" class="underline"> Laura Toledano / Wikimedia Commons</a>; Photo 2 - Jason Bisping;</li>
            <li>Solution #10: Header - <a href="https://www.flickr.com/photos/149368236@N06/33841163696/in/photolist-TyqNXA-Toeg4d-Skuvdo-Toe6kW-Toe7rU-TC1KQB-TyqRih-TC4AcH-SkvXgd-TC4HUr-ToefqE-T2Yo9d-TyqMPy-TC4xyB-TC4xhe-SkuEkb-TC4HKt-SobmNV-TqASVZ-TC4zHr-TC4xDB-T33K77-TC4CC4-TC4ycv-SkuuQE-TyqB19-SkuuyC-SkuvCG-T33UoJ-Tys2X1-TyqR2A-To9FbS-Skuup9-TC4CgT-TC4ysa-TC4AYx-TC4xmH-TyqRbU-TC4EmK-Skuu6o-TqC2m4-TC4xQZ-T33Y9u-TC4y5X-T33PzA-TC4HAk-TC4ETr-Tys3n9-TyqJAu-SkuvL7" target="_blank" class="underline"> Stephen Yang / The Solutions Project</a>; Photo 1 - Pujanak on Wikimedia Commons; Photo 2 - Photo by Abbie Trayler-Smith / Panos Pictures / Department for International Development;</li>
            <li>Solution #11: Header - <a href="https://unsplash.com/photos/vrT3Z9UKFEg" target="_blank" class="underline"> Christian Widell on Unsplash</a>; Photo 1 - Sarah Manning; Photo 2 - CSTAF;</li>
            <li>Solution #12: Header - <a href="https://unsplash.com/photos/FJ4HrTV4vsg" target="_blank" class="underline"> Ken Shono on Unsplash</a>; Photo 1 - <a href="https://www.grida.no/publications/355" target="_blank" class="underline">Levi Westerveld</a>; Photo 2 - David Stanley on Flickr;</li>
            <li>Solution #13: Header - <a href="https://unsplash.com/photos/Enhs8UrXEb0" target="_blank" class="underline"> Johannes Plenio on Unsplash</a>; Photo 1 - Beman Herish on Wikimedia commons; Photo 2 - Inside the Greenhouse;</li>
            <li>Solution #14: Header - <a href="https://unsplash.com/photos/wFxdx8D4CAg" target="_blank" class="underline"> Phil DuFrene on Unsplash</a>; Photo 1 - <a href="https://unsplash.com/photos/Pw7i-YVg5uM" target="_blank" class="underline">Juliane Liebermann on Unsplash</a>; Photo 2 - Peter Osnes;</li>
            <li>Solution #15: Header - <a href="https://www.flickr.com/photos/nrel/36226530481/in/photolist-Xcdrp8-XknBaj-WNdjC9-W8rvbq-WaQFCZ-WNdmUy-Xcdpbv-Xknwg1-W8rKvs-WNdja5-Xknkg5-WNdn1L-W8rYbh-WNdnNN-W8rRVw-Xcdy9v-W8rHP1-Xkng1b-XknopE-XccRUT-XccRoT-WNdaSo-WNdCRd-WaR18e-Xphv6v-Xknfum-XcdowK-XcdczV-Xcd5qe-XcdcKK-XccZFe-W8rUs5-WaQTJ2-Xcdh18-XknwFE-Xkncud-WNdEgs-WaRfkc-XcduV6-W8rVTS-XcduiV-XcdaNt-XknhT9-Xcda7i-WaQskg-WaQrLk-X9ooUs-Xknut3-WaR98T-WNdSrj" target="_blank" class="underline"> Dennis Schroder / NREL / Flickr</a>; Photo 1 - IRENA / Flickr; Photo 2 - <a href="https://www.flickr.com/photos/kenlund/17353631996" target="_blank" class="underline">Ken Lund / Flickr</a>;</li>
            <li>Solution #16: Header - <a href="https://www.flickr.com/photos/ianhaycox/2124375035/" target="_blank" class="underline"> Ian Haycox on Flickr</a>; Photo 1 - Zane Selvans / Flickr; Photo 2 - Sarah Manning;</li>
            <li>Solution #17: Header - <a href="https://unsplash.com/photos/-1YK96H2tHM" target="_blank" class="underline"> C. Z. Shi on Unsplash</a>; Photo 1 - <a href="https://unsplash.com/photos/DhDdPkQvVAE" target="_blank" class="underline">Hu Chen on Unsplash</a>; Photo 2 - <a href="https://unsplash.com/photos/ahUR_9O-J10" target="_blank" class="underline">Dhruv Mehra on Unsplash</a>;</li>
            <li>Solution #18: Header - <a href="https://unsplash.com/photos/lwwM_dotpcs" target="_blank" class="underline"> Justin Lane on Unsplash</a>; Photo 1 - Green Energy Futures on Flickr; Photo 2 - Sarah Manning and Samantha Dunlap;</li>
            <li>Solution #19: Header - <a href="https://unsplash.com/photos/hKAQfdk_4gw" target="_blank" class="underline"> Max Ducourneau on Unsplash</a>; Photo 1 - C. Schubert (CCAFS) on Flickr; Photo 2 - Patrick Campbell;</li>
            <li>Solution #20: Header - <a href="https://commons.wikimedia.org/wiki/File:Intercropping_coconut_n_Tagetes_erecta.jpg" target="_blank" class="underline"> Ezhuttukari / Wikimedia Commons</a>; Photo 1 - Julius Atia / World Agroforestry on Flickr; Photo 2 - Neil Palmer (CIAT) on Wikimedia Commons;</li>
            <li>Credits: Cartoon portraits by Melisande Osnes</li>
        </ul>
        We tried to use only public domain or creative commons licensed photos unless permission was otherwise obtained. If we mistakely included a photo that no longer falls under public usage rights, please email <a href="mailto:sarah.manning@colorado.edu" class="underline">sarah.manning@colorado.edu</a> for removal.
    </p>
    
    
    <!-- <p class="pb-2">
      You can visit the <a href="https://www.drawdown.org/" class="font-bold text-drawdown hover:underline">Project Drawdown website</a>
    </p> -->

  
  </div>
</template>

<script>
import { mapState, mapMutations} from "vuex";

export default {
  name: 'Credits',
  components: {
    
  },
  computed: {
        ...mapState(["solutionIndex"])
  },
  methods: {
        ...mapMutations(["setSolutionIndex"])
  },
}

</script>